// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-guidelines-jsx": () => import("./../src/pages/guidelines.jsx" /* webpackChunkName: "component---src-pages-guidelines-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

